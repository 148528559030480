<template>
  <div class="chart-info w-full relative">
    <vx-card v-if="!isLoading" class="h-full">
      <div class="flex flex-row items-start">
        <div class="relative w-full">
          <vs-list-header :title="$t('views.home.agentConversationRating')" class="mb-0 pb-2 card-title-font-size" color="primary"></vs-list-header>
          <div class="chart-info__subtitle">{{ $t('views.home.agentConversationRatingSubtitle') }}</div>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="$t('info.dashboard.agentConversationRatingInfo')" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>

      <div class="mt-5 agent-converting-main-value">{{ totalAmount }}</div>
      <div class="mb-5 agent-converting-main-title">{{ $t('views.home.totalRatedConversations') }}</div>

      <div v-if="visitorStatusOverviewData" class="visitor-status-list">
        <div v-for="(item, index) in Object.keys(visitorStatusOverviewData)" :key="visitorStatusOverviewData[item].type" class="visitor-status-list__item">
          <div class="visitor-status-list__item__info">
            <div class="visitor-status-list__item__info__title">
              {{ $t(demoVisitorStatusOverviewDataLabels[index].name) }}
            </div>
          </div>

          <div class="visitor-status-list__item__amount">{{ visitorStatusOverviewData[item].amount }}</div>
          <div class="visitor-status-list__item__percent">{{ visitorStatusOverviewData[item].percent }}%</div>
        </div>
      </div>
    </vx-card>
    <placeholder-text-small v-else />
  </div>
</template>
<script>
import { analyticsData } from './analyticsData'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    PlaceholderTextSmall,
  },

  mixins: [analyticsData],

  props: {
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      demoTotalAmount: 500,
      demoVisitorStatusOverviewDataLabels: [
        {
          name: 'info.dashboard.highBuyingIntent',
          description: 'views.home.prospectsWithBuyingIntent'
        },
        {
          name: 'info.dashboard.moderateBuyingIntent',
          description: 'views.home.mediumInterest'
        },
        {
          name: 'info.dashboard.noBuyingIntent',
          description: 'views.home.prospectsWithoutBuyingIntent'
        },
        {
          name: 'views.home.supportRequest',
          description: 'views.home.customersNeededHelp'
        }
      ],
      demoVisitorStatusOverviewData: {
        good:
        {
          type: 'good',
          amount: '42',
          percent: '48'
        },
        neutral:
        {
          type: 'neutral',
          amount: '11',
          percent: '12'
        },
        bad:
        {
          type: 'bad',
          amount: '29',
          percent: '33'
        },
        support:
        {
          type: 'support',
          amount: '5',
          percent: '6'
        }
      },
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  computed: {
    totalAmount() {
      if (!this.isVegaActivated) {
        return this.demoTotalAmount
      }

      if (!(this.vegaAgentConversationRating && this.vegaAgentConversationRating.length > 0)) {
        return 0
      }

      // Sum all properties except the conversation item
      const summedValue = this.vegaAgentConversationRating.reduce((acc, item) => {
        for (const [key, value] of Object.entries(item)) {
          if (key !== 'conversation') {
            acc += value
          }
        }

        return acc
      }, 0)

      return summedValue
    },

    visitorStatusOverviewData() {
      if (!this.isVegaActivated) {
        return this.demoVisitorStatusOverviewData
      }

      const data = this.demoVisitorStatusOverviewData
      const dataKeys = Object.keys(data)

      dataKeys.forEach((key) => {
        data[key].amount = 0
        data[key].percent = 0
      })

      if (!this.vegaAgentConversationRating) {
        return data
      }

      this.vegaAgentConversationRating.forEach((vegaDataItem) => {
        const vegaDataItemKeys = Object.keys(vegaDataItem)

        vegaDataItemKeys.forEach((key) => {
          if (data[key]) {
            data[key].amount = data[key].amount + vegaDataItem[key]
          }
        })
      })

      dataKeys.forEach((key) => {
        let percent = 0

        if (this.totalAmount) {
          const calculatedPercent = Math.round((data[key].amount / this.totalAmount) * 100)

          if (!isNaN(calculatedPercent)) {
            percent = calculatedPercent
          }
        }

        data[key].percent = percent
      })

      return data
    }
  }
}
</script>
<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}
.agent-converting-main {
  &-value {
    font-weight: 700;
    font-size: 49px;
    line-height: 59px;
    color: #726f7b;
  }

  &-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #c2c0c5;
  }
}
</style>


<style lang="scss" scoped>
.visitor-status-list {
  margin-top: auto;
  padding-top: 24px;
  overflow-x: auto;

  &__item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &__info {
      margin-right: auto;
      text-align: left;

      &__title {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: rgb(118, 116, 128);
      }
    }

    &__amount {
      margin-left: 20px;
      min-width: 46px;
      font-size: 13px;
      line-height: 16px;
      color: rgb(118, 116, 128);
    }

    &__percent {
      width: 40px;
      min-width: 40px;
      text-align: right;
      font-size: 13px;
      line-height: 16px;
      color: rgb(194, 192, 197);
    }

    &__detailed-description {
      flex-grow: 1;
      margin-left: 50px;
    }
  }
}
</style>

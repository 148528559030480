var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", [
            _c("div", { staticClass: "flex flex-row items-center" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "card-title-font-size",
                    attrs: { title: _vm.componentTitle, color: "primary" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2 vs-list--header" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      staticStyle: { width: "17px" },
                      attrs: { text: _vm.componentTooltip, position: "top" },
                    },
                    [
                      _c("img", {
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            !_vm.isMobile
              ? _c(
                  "div",
                  [
                    _vm.widgetType === "widget"
                      ? _c("vue-apex-charts", {
                          staticClass: "mb-12 mt-4",
                          attrs: {
                            type: "donut",
                            height: "200",
                            options:
                              _vm.sessionsByType.sessionsByTypeDonut
                                .chartOptions,
                            series: _vm.sessionsByType.sessions.series,
                          },
                        })
                      : _vm._e(),
                    _vm.widgetType === "vega-popup"
                      ? _c("vue-apex-charts", {
                          staticClass: "mb-12 mt-4",
                          attrs: {
                            type: "donut",
                            height: "200",
                            options:
                              _vm.vegaSessionsByType.sessionsByTypeDonut
                                .chartOptions,
                            series: _vm.vegaSessionsByType.sessions.series,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.widgetType === "widget"
              ? _c(
                  "ul",
                  _vm._l(
                    _vm.sessionsByType.sessions.analyticsData,
                    function (sessionsData) {
                      return _c(
                        "li",
                        { key: sessionsData.type, staticClass: "flex mb-3" },
                        [
                          _c("feather-icon", {
                            style: { color: sessionsData.color },
                            attrs: {
                              icon: sessionsData.icon,
                              svgClasses: ["h-5 w-5 stroke-current"],
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "ml-2 inline-block font-semibold" },
                            [_vm._v(_vm._s(sessionsData.type))]
                          ),
                          _c("span", { staticClass: "mx-2" }, [_vm._v("-")]),
                          _c("span", { staticClass: "mr-4" }, [
                            _vm._v(_vm._s(sessionsData.percent) + "%"),
                          ]),
                          _c("div", { staticClass: "ml-auto flex -mr-1" }, [
                            _c("span", { staticClass: "ml-10" }, [
                              _vm._v(_vm._s(sessionsData.total)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  0
                )
              : _c(
                  "ul",
                  _vm._l(
                    _vm.vegaSessionsByType.sessions.analyticsData,
                    function (sessionsData) {
                      return _c(
                        "li",
                        { key: sessionsData.type, staticClass: "flex mb-3" },
                        [
                          _c("feather-icon", {
                            style: { color: sessionsData.color },
                            attrs: {
                              icon: sessionsData.icon,
                              svgClasses: ["h-5 w-5 stroke-current"],
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "ml-2 inline-block font-semibold" },
                            [_vm._v(_vm._s(sessionsData.type))]
                          ),
                          _c("span", { staticClass: "mx-2" }, [_vm._v("-")]),
                          _c("span", { staticClass: "mr-4" }, [
                            _vm._v(_vm._s(sessionsData.percent) + "%"),
                          ]),
                          _c("div", { staticClass: "ml-auto flex -mr-1" }, [
                            _c("span", { staticClass: "ml-10" }, [
                              _vm._v(_vm._s(sessionsData.total)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", [
            _c("div", { staticClass: "flex flex-row items-center" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "card-title-font-size",
                    attrs: { title: _vm.componentTitle, color: "primary" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2 vs-list--header" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      staticStyle: { width: "17px" },
                      attrs: {
                        text: _vm.$t("info.dashboard.requestsByTypeInfo"),
                        position: "top",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c("vue-apex-charts", {
                  staticClass: "mb-12 mt-4",
                  attrs: {
                    type: "donut",
                    height: "200",
                    options:
                      _vm.campaignRequestsByType.requestsByTypeDonut
                        .chartOptions,
                    series: _vm.campaignRequestsByType.requests.series,
                  },
                }),
              ],
              1
            ),
            _c(
              "ul",
              _vm._l(
                _vm.campaignRequestsByType.requests.analyticsData,
                function (requestsData) {
                  return _c(
                    "li",
                    { key: requestsData.type, staticClass: "flex mb-3" },
                    [
                      _c("feather-icon", {
                        style: { color: requestsData.color },
                        attrs: {
                          icon: requestsData.icon,
                          svgClasses: ["h-5 w-5 stroke-current"],
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "ml-2 inline-block font-semibold" },
                        [_vm._v(_vm._s(requestsData.type))]
                      ),
                      _c("span", { staticClass: "mx-2" }, [_vm._v("-")]),
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(_vm._s(requestsData.percent) + "%"),
                      ]),
                      _c("div", { staticClass: "ml-auto flex -mr-1" }, [
                        _c("span", { staticClass: "ml-10" }, [
                          _vm._v(_vm._s(requestsData.total || 0)),
                        ]),
                      ]),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
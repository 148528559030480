<template>
  <div>
    <vx-card>
      <div class="data-block-heading">
        <h3 class="data-block-heading__button" @click="$serverBus.$emit('fetch-data-in-blocks', { query: 'bq-communication-analytics' })">
          <DownArrowIcon
            :width="14"
            :height="14"
            :style="`transform: rotate(${isCollapsedCommunicationAnalyticsBlock ? '0deg' : '180deg'}); margin-right: 12px;`"
          />
          Communication Analytics
        </h3>
      </div>
    </vx-card>

    <template v-if="!isCollapsedCommunicationAnalyticsBlock">
      <div v-if="isVegaActivated" class="w-full analytics-container vega-analytics-container-blocks">
        <div class="w-full analytics-container--left-grow1 analytics-container-charts">
          <div class="flex ml-0 margin-rt" v-if="activeUserInfo.superadmin || activeUserInfo.impersonated">
            <VegaConversationStatus :isLoading="isVegaConversationStatusDataLoading" triggeredBy="widget" />
          </div>

          <div class="flex margin-rt" v-if="activeUserInfo.superadmin || activeUserInfo.impersonated">
            <VegaConversationStatus :isLoading="isVegaConversationStatusDataLoading" triggeredBy="vega-popup" />
          </div>

          <div class="flex margin-rt">
            <VegaAgentConversationRating :isLoading="isVegaAgentConversationRatingDataLoading" />
          </div>

          <div class="flex">
            <VegaFilledContactFormStatus :isLoading="isVegaFilledContactFormStatusDataLoading" />
          </div>
        </div>
      </div>

      <div class="pb-0 analytics-container analytics-container-requests" :class="{ 'analytics-container--mobile': isMobile }">
        <div class="analytics-container--left">
          <div class="mb-2 card-title-font-size">{{ $t('info.dashboard.interactionOfVisitorWithWidgetsAndPopup') }}</div>

          <div class="analytics-container--left-grow1">
            <div class="margin-rt">
              <requests-by-type :isLoading="isRequestsLoading" :isMobile="isMobile" title="views.home.requestsByType" widgetType="widget"></requests-by-type>
            </div>

            <div class="margin-rt">
              <requests-by-type
                :isLoading="isRequestsLoading"
                :isMobile="isMobile"
                title="views.home.requestsByType"
                widgetType="vega-popup"
              ></requests-by-type>
            </div>

            <div class="margin-rt">
              <sessions-by-type :isLoading="isSessionsLoading" widgetType="widget" :isMobile="isMobile" title="views.home.sessionsByType"></sessions-by-type>
            </div>

            <div>
              <sessions-by-type
                :isLoading="isSessionsLoading"
                widgetType="vega-popup"
                :isMobile="isMobile"
                title="views.home.sessionsByType"
              ></sessions-by-type>
            </div>

            <template v-if="!isMobile">
              <div class="margin-lt">
                <campaign-requests-by-type :isLoading="isCampaignRequestsLoading" title="views.home.requestsByType"></campaign-requests-by-type>
              </div>

              <div class="margin-lt">
                <campaign-sessions-by-type :isLoading="isCampaignSessionsLoading" title="views.home.sessionsByType"></campaign-sessions-by-type>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="mt-0 analytics-container" :class="{ 'analytics-container--mobile': isMobile }">
        <div class="analytics-container--left">
          <div v-show="HAS_ADVANCED_DASHBOARD_ACCESS" class="analytics-container--left-grow1">
            <div class="margin-rt">
              <general-info-item :title="'views.home.shownContactForm'" :value="shownContactForm" :isLoading="isContactFormLoading" />
            </div>
            <div class="margin-rt margin-lt">
              <general-info-item :title="'views.home.filledContactForm'" :value="filledContactForm" :isLoading="isContactFormLoading" />
            </div>
            <div class="margin-lt">
              <general-info-item :title="'views.home.bookedAppointment'" :value="bookedContactForm" :isLoading="isContactFormLoading" />
            </div>
          </div>

          <div v-if="!isMobile" class="analytics-container--left-grow1">
            <div>
              <div class="mb-2 card-title-font-size">{{ $t('endscreenFeedback.customerFeedback') }}:</div>

              <end-screen-feedback :isNewEndScreenFeedbackView="false" :value="endScreenFeedbackData" :isLoading="isEndScreenFeedbackDataLoading" />
            </div>
          </div>

          <div v-show="HAS_ADVANCED_DASHBOARD_ACCESS">
            <visitor-locations
              class="analytics-container--right--visitors-height"
              :topVisitors="topVisitors"
              :isVisitorsLocationLoading="isVisitorsLocationLoading"
              :isMobile="isMobile"
            />
          </div>

          <div :class="{ 'ml-0': isMobile && !HAS_ADVANCED_DASHBOARD_ACCESS, 'mt-6 analytics-container--left-grow1': true }">
            <agents class="analytics-container--right--visitors-height" :agents="agents" :isAgentsLoading="isAgentsLoading" />
          </div>
        </div>

        <div class="analytics-container--right">
          <div class="analytics-container--right-grow1">
            <div :class="{ 'ml-0': isMobile && !HAS_ADVANCED_DASHBOARD_ACCESS }" v-if="routeClicks && routeClicks.length > 0">
              <route-clicks class="analytics-container--right--visitors-height" :routeClicks="routeClicks" :isRouteClicksLoading="isRouteClicksLoading" />
            </div>
          </div>

          <div v-if="!isMobile" v-show="HAS_ADVANCED_DASHBOARD_ACCESS" class="analytics-container--right-grow1">
            <device-name
              class="analytics-container--right--visitors-height"
              :deviceData="deviceData"
              v-show="deviceData.length"
              :isDeviceLoading="isDeviceLoading"
              :title="$t('views.home.requestsByDevice')"
            />
          </div>

          <div v-if="!isMobile" v-show="HAS_ADVANCED_DASHBOARD_ACCESS" class="analytics-container--right-grow1">
            <div>
              <table-view :tableData="browserData" :tableConfig="browserDataTableConfig" :isLoading="isBrowserDataLoading" measuredBy="sessions" />
            </div>
          </div>

          <div v-if="!isMobile" v-show="HAS_ADVANCED_DASHBOARD_ACCESS" class="analytics-container--right-grow1">
            <div>
              <table-view
                :tableData="topOrigins"
                :isLoading="isOriginDataLoading"
                :tableConfig="topOriginsTableConfig"
                class="analytics-container--word-break-table"
                measuredBy="requests"
              />
            </div>
          </div>

          <div v-if="!isMobile" v-show="HAS_ADVANCED_DASHBOARD_ACCESS" class="analytics-container--right-grow1">
            <div>
              <table-view
                :tableData="topReferers"
                :isLoading="isRefererDataLoading"
                :tableConfig="topReferersTableConfig"
                class="analytics-container--word-break-table"
                measuredBy="requests"
              />
            </div>
          </div>

          <div v-if="!isMobile" class="analytics-container--right-grow1">
            <end-screen-feedback :isNewEndScreenFeedbackView="true" :value="endScreenFeedbackData" :isLoading="isEndScreenFeedbackDataLoading" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RequestsByType from './RequestsByType.vue'
import CampaignRequestsByType from './CampaignRequestsByType.vue'
import SessionsByType from './SessionsByType.vue'
import CampaignSessionsByType from './CampaignSessionsByType.vue'

import VegaConversationStatus from './VegaConversationStatus.vue'
import VegaFilledContactFormStatus from './VegaFilledContactFormStatus.vue'
import VegaAgentConversationRating from './VegaAgentConversationRating.vue'

import VisitorLocations from './VisitorLocations.vue'
import Agents from './Agents.vue'
import RouteClicks from './RouteClicks'
import DeviceName from './DeviceName.vue'
import TableView from './TableView.vue'

import GeneralInfoItem from './GeneralInfoItem.vue'

import EndScreenFeedback from './EndScreenFeedback.vue'

import DownArrowIcon from '@/components/icons/DownArrowIcon'

export default {
  name: 'AnalyticsContainer',
  components: {
    RequestsByType,
    CampaignRequestsByType,
    SessionsByType,
    CampaignSessionsByType,
    VisitorLocations,
    Agents,
    RouteClicks,
    DeviceName,
    TableView,
    GeneralInfoItem,
    EndScreenFeedback,
    VegaConversationStatus,
    VegaFilledContactFormStatus,
    VegaAgentConversationRating,
    DownArrowIcon
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isRequestsLoading: {
      type: Boolean
    },
    isSessionsLoading: {
      type: Boolean
    },
    isCampaignRequestsLoading: {
      type: Boolean
    },
    isCampaignSessionsLoading: {
      type: Boolean
    },
    isVisitorsLocationLoading: {
      type: Boolean
    },
    topVisitors: {
      type: Array
    },
    isAgentsLoading: {
      type: Boolean
    },
    agents: {
      type: Array
    },
    routeClicks: {
      type: Array
    },
    isRouteClicksLoading: {
      type: Boolean
    },
    isDeviceLoading: {
      type: Boolean
    },
    deviceData: {
      type: Array
    },
    browserData: {
      type: Array
    },
    browserDataTableConfig: {
      type: Object
    },
    isBrowserDataLoading: {
      type: Boolean
    },
    topOrigins: {
      type: Array
    },
    topOriginsTableConfig: {
      type: Object
    },
    isOriginDataLoading: {
      type: Boolean
    },
    topReferers: {
      type: Array
    },
    topReferersTableConfig: {
      type: Object
    },
    isRefererDataLoading: {
      type: Boolean
    },
    shownContactForm: {
      type: Number
    },
    filledContactForm: {
      type: Number
    },
    bookedContactForm: {
      type: Number
    },
    isContactFormLoading: {
      type: Boolean
    },
    endScreenFeedbackData: {
      type: Array
    },
    isEndScreenFeedbackDataLoading: {
      type: Boolean
    },
    HAS_ADVANCED_DASHBOARD_ACCESS: {
      type: Boolean,
      default: false
    },
    isVegaConversationStatusDataLoading: {
      type: Boolean
    },
    isVegaFilledContactFormStatusDataLoading: {
      type: Boolean
    },
    isVegaAgentConversationRatingDataLoading: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      isCollapsedCommunicationAnalyticsBlock: 'dashboard/isCollapsedCommunicationAnalyticsBlock',
      activeUserInfo: 'activeUser'
    })
  }
}
</script>

<style lang="scss" scoped>
.data-block-heading {
  display: flex;

  &__button {
    cursor: pointer;
  }
}

.analytics-container {
  * {
    box-sizing: border-box;
  }
  display: flex;
  margin-top: 10px;
  padding: 0 0 10px;

  &--mobile {
    padding: 10px 0 0;
  }

  &--word-break-table {
    word-break: break-all;
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    &.vega-analytics-container-blocks {
      .analytics-container--left-grow1 > div {
        margin-bottom: 0;
      }
    }

    .margin-rt {
      margin-right: 10px;
    }
    .margin-lt {
      margin-left: 10px;
    }

    &--left {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      &-visitors {
        margin-bottom: 20px;
      }
      &-grow1 {
        display: flex;

        > div {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          margin-bottom: 20px;
        }
      }
    }

    &--right {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      &-grow1 {
        display: flex;
        flex-direction: row;

        > div {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          margin-left: 20px;
          margin-bottom: 20px;
        }
      }

      &--visitors-height {
        height: 309.75px;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .margin-rt {
      margin-right: 0px;
    }
    .margin-lt {
      margin-left: 0px;
    }

    &--left {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      &-visitors {
        margin-bottom: 20px;
      }
      &-grow1 {
        > div {
          margin-bottom: 20px;
        }
      }
    }

    &--right {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;

      &-grow1 {
        > div {
          margin-bottom: 20px;
        }
      }
    }
  }

  &-requests {
    .analytics-container--left-grow1 {
      @media (min-width: 1024px) and (max-width: 1500px) {
        flex-wrap: wrap;

        > div {
          min-width: calc(33.33% - 10px);
          max-width: 33.33%;
        }
      }
    }

    &.analytics-container--mobile {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>

<style lang="scss">
.analytics-container-charts {
  .chart-info {
    .vx-card {
      &__collapsible-content {
        height: 100%;
      }

      &__body {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &__chart-wrapper {
      margin-top: auto;
    }
  }
}
</style>

<template>
  <div class="chart-info flex w-full relative">
    <vx-card v-if="!isLoading">
      <div class="flex flex-row items-start">
        <div class="relative w-full">
          <vs-list-header :title="componentTitle" class="card-title-font-size" color="primary"></vs-list-header>
          <div class="chart-info__subtitle">{{ $t('views.home.conversationStatusSubtitle') }}</div>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="componentTooltip" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>

      <div class="chart-info__chart-wrapper pt-4">
        <vue-apex-charts type="donut" height="230" :options="chartOptions" :series="series" />
      </div>

      <div class="visitor-data-list">
        <div v-for="item in conversationStatusData" :key="item.name" class="visitor-data-list__item">
          <VegaIcon :type="item.type" :width="21" />

          <div>
            <span class="visitor-data-list__item__title">{{ item.name }}</span>
            -
            <span class="visitor-data-list__item__percent"> {{ item.percent }}%</span>
          </div>
        </div>
      </div>
    </vx-card>

    <placeholder-text-small v-else />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

import { analyticsData } from './analyticsData'
import VegaIcon from './VegaIcon.vue'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    VueApexCharts,
    VegaIcon,
    PlaceholderTextSmall
  },

  mixins: [analyticsData],

  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    triggeredBy: {
      type: String,
      default: 'widget'
    },
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      key: Math.random().toString(36).substring(2, 15),
      infoImg: require('@/assets/images/elements/info.svg'),
      demoTotalAmount: 500,
      demoSeries: [52, 22, 14, 11, 1, 0],
      demoConversationStatusData: [
        {
          type: 'onfire',
          name: 'On Fire',
          percent: '52'
        },
        {
          type: 'hot',
          name: 'Hot',
          percent: '22'
        },
        {
          type: 'warm',
          name: 'Warm',
          percent: '14'
        },
        {
          type: 'mild',
          name: 'Mild',
          percent: '11'
        },
        {
          type: 'cold',
          name: 'Cold',
          percent: '1'
        },
        {
          type: 'frozen',
          name: 'Frozen',
          percent: '0'
        }
      ],
    }
  },

  computed: {
    totalAmount() {
      if (!this.isVegaActivated) {
        return this.demoTotalAmount
      }

      if (!this.vegaConversationStatus) {
        return 0
      }

      return Object.values(this.vegaConversationStatus.filter((item) => item.triggeredBy === this.triggeredBy)).reduce(
        (accumulator, currentValue) => accumulator + currentValue.visitor_count,
        0
      )
    },

    chartOptions() {
      return {
        labels: ['On Fire', 'Hot', 'Warm', 'Mild', 'Cold', 'Frozen'],
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val.toFixed()}%`
          }
        },
        legend: { show: false },
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return `${val}%`
            }
          }
        },
        chart: {
          offsetY: 0,
          type: 'donut',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: 'Lato',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Lato',
                  offsetY: 0,
                  formatter: (val) => {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#262629',
                  formatter: () => {
                    return this.totalAmount
                  }
                }
              }
            }
          }
        },
        stroke: { width: 0 },
        colors: ['rgba(254, 18, 78, 0.62)', 'rgba(224, 0, 0, 0.3)', '#54f23e', '#33f49c', '#0ed1d1', '#08a4e8']
      }
    },

    series() {
      if (!this.isVegaActivated) {
        return this.demoSeries
      }

      if (!this.vegaConversationStatus) {
        return [0, 0, 0, 0, 0, 0]
      }

      return this.conversationStatusData.map((item) => item.percent)
    },

    conversationStatusData() {
      if (!this.isVegaActivated) {
        return this.demoConversationStatusData
      }

      const vegaData = this.vegaConversationStatus.filter((item) => item.triggeredBy === this.triggeredBy)

      let demoModifiedData = this.demoConversationStatusData.map((item) => {
        item.percent = 0
        item.amount = 0

        return { ...item }
      })

      if (!vegaData) {
        return demoModifiedData
      }

      const totalVisitorsCount = vegaData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.visitor_count,
        0
      )

      vegaData.forEach((vegaDataItem) => {
        demoModifiedData = demoModifiedData.map((item) => {
          if (item && vegaDataItem && vegaDataItem.visitor_count && item.type === vegaDataItem.score_category) {
            item = {
              ...item,
              amount: item.amount + vegaDataItem.visitor_count
            }
          }

          return item
        })
      })

      demoModifiedData.forEach((item) => {
        let percent = 0

        if (totalVisitorsCount && item.amount) {
          const calculatedPercent = Math.round((item.amount / totalVisitorsCount) * 100)

          if (!isNaN(calculatedPercent)) {
            percent = calculatedPercent
          }
        }

        item.percent = percent
      })

      return demoModifiedData
    },

    componentTitle() {
      if (this.triggeredBy === 'widget') {
        return `${this.$i18n.t('views.home.conversationStatus')} (${this.$i18n.t('routes.dialog')})`
      }

      return `${this.$i18n.t('views.home.conversationStatus')} (VEGA Popup)`
    },

    componentTooltip() {
      if (this.triggeredBy === 'widget') {
        return this.$i18n.t('info.dashboard.conversationStatusInfo')
      }

      return this.$i18n.t('info.dashboard.conversationStatusInfoViaVegaPopup')
    },
  }
}
</script>

<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>

<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}

.chart-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: -32px auto 0;
  width: 155px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #c2c0c5;
  transform: translateX(-50%);
}

.visitor-data-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  &__item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 50%;
    font-size: 14px;
    color: #767480;

    &__title {
      margin-left: 10px;
      font-weight: 700;
    }
  }
}
</style>
